import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async'
import './Style/index.css';
import './Style/Login.css';
import './Style/Abbonamento.css';
 
import { Master } from './Master';
import { hydrate } from 'react-dom';



const helmetContext = {};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);



root.render(
  <HelmetProvider>
    <Master></Master>
  </HelmetProvider>
)




/*
const rootElement = document.getElementById("root") as HTMLElement;
if (rootElement.hasChildNodes()) {
  hydrate(<HelmetProvider context={helmetContext}>
    <Master></Master>
  </HelmetProvider>, rootElement);
} else {
  root.render(
    <HelmetProvider context={helmetContext}>
      <Master></Master>
    </HelmetProvider>
  )
}

*/
