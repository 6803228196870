
//import React from "react";
import { Link, useNavigate } from "react-router-dom";
//import { Prezzi } from "./Prezzi";
import homeIMG from "../img/homeImg.webp"
import { Helmet } from 'react-helmet-async'
//import Spunta from "../img/Spunta.jpeg"
//<img id="SpuntaIcon" src={Spunta} alt="Spunta" />
import { AsyncImage } from "../Components/AsyncImage"
import YouTubeImg from "../img/YouTubeImg.webp"
import trust from "../img/trust.png"
import chevronforwardoutline from '../img/chevron-forward-outline.svg'
import { IonCard } from "@ionic/react";

type props = {
    width_: any
}
export const Home = ({ width_ }: props) => {
    const navigate = useNavigate()

    return (
        <div className="home-container">
            <div id="TestoSuVideoSx">

                <Helmet>
                    <title>EasyMAD.it - Interpelli scuola e Candidature per supplenze</title>
                    <meta name="description" content="Interpelli da tutte le scuole d'Italia. Un servizio di candidature per supplenze semplice ed efficace. Cerchiamo di facilitare l'ottenimento di un incarico per gli aspiranti docenti e personale ata riducendo la pesantezza butocratica del mondo della scuola" />
                    <link rel="canonical" href="https://easymad.it" />

                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="EasyMAD.it - Interpelli scuola e Candidature per supplenze" />
                    <meta property="og:description" content="Interpelli da tutte le scuole d'Italia. Un servizio di candidature per supplenze semplice ed efficace. Cerchiamo di facilitare l'ottenimento di un incarico per gli aspiranti docenti e personale ata riducendo la pesantezza butocratica del mondo della scuola" />
                    <meta property="og:image" content="https://easymad.it/Img/Copertina.png" />
                    <meta property="og:url" content="https://easymad.it" />
                </Helmet>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>


                </div>
            </div>



            <div style={{ minHeight: "25vw", background: "var(--color10)", display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "space-between" }}>
                <AsyncImage className="moveToLeft" style={{ borderRadius: "60%", zIndex: "4", width: "min(70vw, 700px)", height: "min(39.37vw, 393.75px)", borderWidth: "1.5vw", borderStyle: "dashed", borderColor: "white", boxShadow: "0 0px 0px rgba(0, 0, 0, 0.4)", position: 'absolute', right: "max(-20vw, -200px)", top: "max(7rem, 10vw)" }} src={homeIMG} alt="Diventa_PROFESSORE_o_ATA" />

                <h2 className="moveToRight" style={{ color: "white", fontSize: "min(3.4vw, 25px)", fontWeight: "600", padding: "5vw 0 1vw 5vw", margin: "0", width: "50vw", textAlign: "start", lineHeight: "1.5", textShadow: "0 0px 10px hsl(164, 51%, 31%)", zIndex: "2" }}>La prima <b className="Cursor" style={{ color: "var(--color9)", fontSize: "min(4.2vw, 28px)", textShadow: "none", fontWeight: "900" }} onClick={() => { window.open('/Interpelli') }}>banca dati</b> di <b className="Cursor" style={{ color: "var(--color9)", fontSize: "min(4vw, 27px)", textShadow: "none", fontWeight: "900" }} onClick={() => { window.open('/Interpelli') }}> interpelli</b> costantemente aggiornata. </h2>
                <h2 className="moveToRight" style={{ color: "var(--color9)", fontSize: "min(3.4vw, 25px)", fontWeight: "600", padding: "2rem 2vw 2rem 5vw", margin: "0rem 0", width: "max(40vw, 170px)", textAlign: "start", lineHeight: "1.5", textShadow: "0 0px 0px hsl(164, 51%, 31%)", zIndex: "2", background: /*width_ < 1200 ? "var(--color6)" :*/ "none", borderBottomRightRadius: "1rem", borderTopRightRadius: "5rem" }}>Servizio di <b className="Cursor" style={{ color: "var(--color9)", textShadow: "none", fontSize: "min(4.2vw, 28px)" }} onClick={() => { window.open('/InvioMAD') }}>candidature per supplenze</b> capillare e verificato.</h2>

            </div>


            <div style={{ position: "relative", border: "0px solid white", margin: "0", height: 0, background: "white", minHeight: "10vw", width: "100%" }}>
                <div style={{ borderBottom: "15vw solid white", borderLeft: "120vw solid white", borderTopRightRadius: "90%", boxShadow: "none", background: "white", top: "-13vw", position: "relative", zIndex: "0" }}></div>
            </div>


            <section id="Container-ComeFunzionaMADIT" className="ContainerInfrmazioni" style={{ background: "none" }}>

                <div id="RowsTextnHome">
                    <div style={{ paddingBottom: "2rem" }}>
                        <h2 className="CosèlaMad" id="DubbiSullaMad">Come funzionano gli <b className="MADIT">interpelli?</b></h2>
                        <p>Ogni scuola, dopo aver attinto dalle graduatorie, ogni qual volta si trova ad aver bisogno di un docente o di un collaboratore scolastico pubblica sul proprio sito un <b>interpello</b>. L'intepello rappresenta la comunicazione della scuola alla cittadinanza, e quindi agli aspiranti docenti e collaboratori, che c'è la <b>necessità di ricoprire un incarico altrimenti vacante</b>.</p>
                        <p>Insieme all'interpello viene pubblicato un <b>allegato</b> nel quale si specifica come gli aspiranti possono candidarsi per ottenere l'incarico. <b>Possono rispondere agli interpelli tutti i docenti e collaboratori scolastici, anche se iscritti alle graduatorie di un'altra provincia.</b></p>
                        <br />
                        <p><b>Per riuscire ad ottenere un incarico</b> rispondendo ad un interpello è di cruciale importanza venire a conoscenza il <b>prima possibile</b> che l'interpello è stato pubblicato ed avere a disposizione i relitivi allegati per sapere come è opportuno candidarsi per l'incarico.</p>
                        {/*<p>Bisogna quindi <b>passare le giornate a controllare i siti delle scuole</b> per riuscire a rispondere in maniera tempestiva ad un intepello, cosa che è ovviamente <b>poco realistica</b>.</p>*/}

                        <h2 className="CosèlaMad" id="" style={{ padding: "1.5rem 0 0.5rem 0" }} >Perché usare EasyMAD.it <b className="MADIT">per gli interpelli?</b></h2>
                        <p><b>Prima di tutto, è GRATUITO! EasyMAD.it</b> ha come obiettivo il contrasto all'eccessiva burocratizzazione del settore scuola, che porta spesso alla frustrazione gli aspiranti docenti e collaboratori scolastici. È questo il caso degli interpelli per i quali EasyMAD.it ha sviluppato un algoritmo di <b>monitoraggio costante</b> delle migliaia di siti scolastici in modo da pubblicare in <b>maniera tempestiva</b> ogni aggiornamento riguardo ai posti vacanti in <b>tutte le scuole d'Italia</b>. Inoltre, per evitare di dover costantemente monitorare il nostro sito, abbiamo ideato una newsletter che consente, tramite un sistema di <b>notifiche</b> via mail, di essere avvisati <b>gratuitamente</b> quando un <b>nuovo interpello viene pubblicato</b>. </p>
                    </div>
                </div>

                <div>
                    <button className="button" style={{ margin: "2rem  auto", fontSize: "14px", padding: "0.75rem 1rem" }} onClick={() => { navigate('/interpelli') }}><a id="linkInformazioni" href='/interpelli'>
                        Vai agli interpelli
                        <AsyncImage src={chevronforwardoutline} style={{ width: "1.2rem", height: "1.2rem" }}></AsyncImage>
                    </a>
                    </button>
                </div>

            </section>


           { /*<section id="Container-ComeFunzionaMADIT" className="ContainerInfrmazioni">
                <h2 id="ComeFunzionaMADIT">È ancora possibile inviare la MAD?</h2>
                <p>Per quanto riguarda le <b>scuole paritarie</b> la modalità di candidatura <b>non è cambiata:</b> le scuole nel momento del bisogno scelgono tra gli aspiranti che hanno presentato autocandidatura.</p>
                <br />
                <p>Per quanto riguarda le <b>scuole statali</b> la mad è stata scavalcata in importanza dagli <b className="Cursor" onClick={() => { navigate('/interpelli') }}><u style={{ color: "var(--color10)" }}>interpelli</u></b>: terminate le graduatorie le scuole pubblicano gli interpelli per ricercare il personale. Se non ci sono risposte all'interpello, oppure se c'è una particolare urgenza <b>le scuole ricorrono agli elenchi dei candidati che hanno presentato la MAD. Molte segreterie scolastiche hanno già dichiarato che, trovando funzionale il sistema della messa a disposizione, continueranno a reclutare il personale con questa modalità.</b></p>
                <br />
                <p>Per trovare gli interpelli ed essere avvisati quando un nuovo interpello viene pubblicato sulle proprie classi di concorso accedi gratuitamente alla nostra piattaforma <b className="Cursor" onClick={() => { navigate('/interpelli') }}><u style={{ color: "var(--color10)" }}>interpelli</u></b>:</p>
            </section>*/}


            <section id="Container-ComeFunzionaMADIT" className="ContainerInfrmazioni" >
                <h2 id="ComeFunzionaMADIT">Come funziona l'invio mad con <b className="MADIT">EasyMAD.it</b></h2>
                <p>A differenza dei servizi offerti dai competitor che sono lenti, macchinosi e complessi, <b>EasyMAD.it</b>  offre un servizio che consente di inviare la domanda in pochissimi minuti, in tutte le province italiane e con un solo click.</p>
                <div>
                    <p>Cliccando su <Link to={"/InvioMAD"} id="InviaLink" onClick={() => { document.documentElement.scrollTop = 0 }}><b>Invia MAD</b></Link>, potrai scegliere la tipologia di MAD da inviare (<b>Docente o Ata</b>). Ti verrà poi richiesto di:</p>
                    <ul className="ListaHome">
                        <li className="liHome"><b></b>Inserire i dati relativi alla tua domanda.</li>
                        <li className="liHome"><b></b>Selezionare <b>Grado d'Istruzione</b>, <b>Classi di concorso</b> o <b>Profili professionali</b> e <b>Province</b> desiderate.</li>
                        <li className="liHome"><b></b>Controllare i dati inseriti nella pagina di <b>Riepilogo</b>.</li>
                        <li className="liHome"><b></b>Confermare l'invio della tua MAD.</li>
                    </ul>

                    <p><b>Riceverai le ricevute di invio delle domande di messa a disposizione al tuo indirizzo di posta personale, indicato in fase di complilazione dei dati, al fine di verificarne il corretto invio.</b></p>
                </div>
                <p>Il team di <b className="MADIT">EasyMAD.it</b> rimane sempre a completa disposizione dell'utente in tutte le fasi del processo di messa a disposizione. Un supporto tecnico ed informativo rimane sempre attivo nei canali disponibili nella sezione <Link to={"/Contatti"} className="MADIT" onClick={() => { document.documentElement.scrollTop = 0 }}><b className="MADIT">Contatti</b></Link>.</p>
                <div>
                    <button className="button" style={{ margin: "2rem  auto", fontSize: "14px", padding: "0.75rem 1rem" }} onClick={() => { navigate('/mad-online-quali-sono-i-vantaggi') }}><a id="linkInformazioni" href='/mad-online-quali-sono-i-vantaggi'>
                        Perchè la MAD Online
                        <AsyncImage src={chevronforwardoutline} style={{ width: "1.2rem", height: "1.2rem" }}></AsyncImage>
                    </a>
                    </button>
                </div>
            </section>

            <section id="Container-ComeFunzionaMADIT" className="ContainerInfrmazioni" style={{ background: "none" }}>

                <div id="ColumnsTextnHome">
                    <div id="RowsTextnHome">
                        <div>
                            <h2 className="CosèlaMad" id="DubbiSullaMad">Cos'è la MAD?</h2>
                            <p>La <b>messa a disposizione</b>, in acronimo “<b>MAD</b>", è un'autocandidatura che si presenta alle scuole per dichiarare la propria disponibilità ad effettuare supplenze come personale docente oppure come personale amministrativo, tecnico e ausiliario (ATA). È uno strumento molto utile che permette ogni anno a migliaia di aspiranti di lavorare nelle scuole italiane.</p>
                        </div>
                        <div>
                            <h2 className="CosèlaMad">Chi può inviarla?</h2>
                            <div>
                                <p><b>L'unico requisito per l'invio della messa a disposizione è il diploma di scuola superiore</b>. Sebbene però sia necessario il solo diploma, costituiscono un vantaggio per l'ottenimento dell'incarico: la laurea Magistrale o titoli equivalenti, i 24 CFU in ambito antropo-psico-pedagogico o altri tipi di abilitazione e tutti i titoli e le certificazioni aggiuntive.</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h2 className="CosèlaMad">Come si invia la MAD?</h2>
                        <p>Ogni scuola ha il suo canale di ricezione, quindi, bisogna sempre visitare il sito web di ogni scuola nella quale ci si vuole candidare e visitare la sezione "messa a di sposizione" per controllare quale canale di ricezione è stato scelto dalla scuola.</p>
                        <p>I canali di ricezione sono raggruppabili in due categorie: la compilazione di un <b>modulo online</b> o l'invio di una <b>mail/pec</b>. (Per una spiegazione più esaustiva vedere il video sottostante).</p>
                        <p>Nel caso di compilazione del modulo online, la maggior parte delle scuole italiane adotta i moduli delle piattaforme <b>Spaggiari (Bergantini), Nuvola, Argo e Axios</b>. La compilazione di questi form diventa quindi necessaria per il capillare invio della propria messa a disposizione. <b>EasyMAD li comprende tutti!</b> In più EasyMAD ti recapita tutte le ricevute di invio sulla tua mail come garanzia dell'avvenuta consegna alle mad.</p>
                    </div>
                </div>

                <div>
                    <button className="button" style={{ margin: "2rem  auto", fontSize: "14px", padding: "0.75rem 1rem" }} onClick={() => { navigate('/messa-a-disposizione-mad-a-cosa-serve-come-funziona-punteggio-dove-quando-requisiti') }}><a id="linkInformazioni" href='/messa-a-disposizione-mad-a-cosa-serve-come-funziona-punteggio-dove-quando-requisiti'>
                        Informazioni complete sulla MAD
                        <AsyncImage src={chevronforwardoutline} style={{ width: "1.2rem", height: "1.2rem" }}></AsyncImage>
                    </a>
                    </button>
                </div>


            </section>

            <section className="PrezziContainer ContainerInfrmazioni">
                <div id="Prezzi">
                    <h2>Prezzi servizio Interpelli</h2>
                    <p className="Incipit-Prezzi">Il servizio di consultazione degli interpelli e il servizio di notifica della pubblicazione di nuovi interpelli sulle proprie classi di concorso sono <b style={{ color: "var(--color10)" }}>totalmente gratuiti</b>. <u><b className="Cursor" onClick={() => { navigate('/interpelli') }}>Clicca qui per iscriverti</b></u></p>
                    <h2 style={{ marginTop: "4rem" }}>Prezzi Candidature Scuola</h2>
                    <p className="Incipit-Prezzi">L'obiettivo di <b className="MADIT">EasyMAD.it</b> è di snellire la pesantezza burocratica del mondo della scuola. A differenza degli altri servizi, che chiedono decine di euro per compilare ed inviare le candidature, noi offriamo quattro piani:</p>
                    <ul className="Incipit-Prezzi ListaHome">
                        <li className="liHome"><b></b>Se desideri candidarti <u>una volta</u> nelle scuole scuole paritarie a tua scelta <b>(Paritarie Standard)</b>.</li>
                        <li className="liHome"><b></b>Se desideri candidarti <u>più volte</u> durante l'anno scolastico nelle scuole scuole paritarie a tua scelta <b>(Paritarie Plus)</b>.</li>
                        <li className="liHome"><b></b>Se desideri inviare la MAD in un numero <u>limitato</u> di province <b>(MAD Standard)</b>.</li>
                        <li className="liHome"><b></b>Se desideri inviare la MAD in un numero <u>illimitato</u> di province <b>(MAD illimitata)</b>.</li>
                    </ul>


                    <h3 style={{ marginTop: "4rem" }}>Scuole Paritarie</h3>
                    <div id="Card-container">
                        <div className="Card Card7" style={{ width: width_ > 900 ? "40%" : "80%" }}>
                            <h4 style={{ color: "white", marginTop: "2rem", fontWeight: "600", textAlign: "center" }}>Paritarie Standard</h4>
                            {/*<p className="costo" style={{ fontWeight: "600", color: "var(--color9)" }}>€14,90</p>*/}
                            <p className="costo" style={{ fontWeight: "600", /*textDecoration: "line-through",*/ fontSize: "22px", color: "var(--color20)"/*"#da4100"*/ }}>€14,90</p>
{/*                            <div className="costo" style={{ fontSize: "16px", color: "black" }}><span style={{ fontSize: "22px", color: "black" }}>-20%</span> fino a fine luglio</div>
                            <p className="costo" style={{ fontWeight: "600", color: "var(--color20)", marginBottom: "0", fontSize: "30px" }}>€11,90</p>
                            <div style={{ textAlign: "center", fontWeight: 500, fontSize: "12px", marginBottom: "2rem", fontStyle: "italic", color: "black" }}>Compra ora, invia quando vuoi</div>*/}
                            <div className="container-Features container-Features6">
                                <div className="features">
                                    <label>Singolo invio in tutte le scuole che si desidera</label>
                                    <label>Classi di concorso/Profili professionali illimitati</label>
                                    <label>Invio veloce (seleziona e invia con un solo click)</label>
                                    <label>Personalizzazione Candidatura</label>
                                    <div className="Cursor" style={{ margin: "10px auto", padding: "10px 20px", fontSize: "14px", borderRadius: "0.5rem", background: "hsla(39, 89%, 66%, 0.96)", color: "white", fontWeight: "600", width: "100%" }} id="InvioMADNav2" /*to='/InvioMAD'*/ onClick={() => { navigate('/Paritarie') }}>Acquista</div>
                                </div>
                            </div>
                        </div>
                        <div className="Card Card6" style={{ width: width_ > 900 ? "40%" : "80%" }}>
                            <h4 style={{ color: "white", marginTop: "2rem", fontWeight: "600", textAlign: "center" }}>Paritarie Premium</h4>
                            <p className="costo" style={{ fontWeight: "600", /*textDecoration: "line-through",*/ fontSize: "22px", color: "var(--color20)"/*"#da4100"*/}}>€19,90</p>
{     /*                       <div className="costo" style={{ fontSize: "16px", color: "black" }}><span style={{ fontSize: "22px", color: "black" }}>-20% </span> fino a fine luglio</div>
                            <p className="costo" style={{ fontWeight: "600", color: "var(--color20)", marginBottom: "0", fontSize: "30px" }}>€19,90</p>
                            <div style={{ textAlign: "center", fontWeight: 500, fontSize: "12px", marginBottom: "2rem", fontStyle: "italic", color: "black" }}>Compra ora, invia quando vuoi</div>*/}
                            <div className="container-Features container-Features7">
                                <div className="features">
                                    <label><b>Invii illimitati</b> durante tutto l'anno scolastico, ogni invio comprende la possibilità di candidarsi in tutte le scuole che si desidera.</label>
                                    <label>Classi di concorso/Profili professionali illimitati</label>
                                    <label>Invio veloce (seleziona e invia con un solo click)</label>
                                    <label>Personalizzazione Candidatura</label>
                                    <div className="Cursor" style={{ margin: "10px auto", padding: "10px 20px", fontSize: "14px", borderRadius: "0.5rem", background: "hsla(24, 89%, 66%, 0.96)", color: "white", fontWeight: "600", width: "100%" }} id="InvioMADNav3" /*to='/InvioMAD'*/ onClick={() => { navigate('/Paritarie') }}>Acquista</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div style={{ height: "1rem", borderBottom: "0.5px solid lightgrey", marginTop: "3rem" }}></div>


                    <h3 style={{ marginTop: "4rem" }}>Messa a disposizione</h3>
                    <div id="Card-container">
                        <div className="Card Card1-5" style={{ width: width_ > 900 ? "40%" : "80%" }}>
                            <h4 style={{ color: "white", marginTop: "2rem", fontWeight: "600", textAlign: "center" }}>MAD Standard</h4>
                            <p className="costo" style={{ fontWeight: "600", fontSize: "22px", color: "#da4100" }}><label style={{/*textDecoration: "line-through",*/ fontSize: "22px", color: "hsl(164, 85%, 29%)"/*"#da4100"*/ }}>€9,90 + €1</label><label className="" style={{/*textDecoration: "line-through",*/ fontSize: "18px", color: "hsl(164, 85%, 29%)"/*"#da4100"*/ }}>/provincia</label></p>
{  /*                          <div className="costo" style={{ fontSize: "16px", color: "black" }}><span style={{ fontSize: "22px", color: "black" }}>-20% </span> fino a fine luglio</div>
                            <p className="costo" style={{ fontWeight: "600", color: "hsl(164, 85%, 29%)", marginBottom: "0", fontSize: "30px" }}>€7,90 + €0.80<label className="PerProvincia" style={{ color: "hsl(164, 85%, 29%)" }}>/provincia</label></p>
                            <div style={{ textAlign: "center", fontWeight: 500, fontSize: "12px", marginBottom: "2rem", fontStyle: "italic", color: "black" }}>Compra ora, invia quando vuoi</div>*/}

                            {/*<p className="costo" style={{ color: "var(--color9)" }}>€9,90 + €1<label className="PerProvincia">/provincia</label></p>*/}
                            <div className="container-Features container-Features1-5">
                                <div className="features">
                                    <label>Un grado di istruzione</label>
                                    <label className="label1-5">Prezzo variabile in base al numero di province/regioni scelte</label>
                                    <label>Invio tramite portali <b>Spaggiari (Bergantini), Nuvola, Argo e Axios</b></label>
                                    <label>Classi di concorso/Profili professionali illimitati</label>
                                    <label>Invio veloce (seleziona e invia con un solo click)</label>
                                    <label>Personalizzazione Mail</label>
                                    <label>Possibilità di invio alle scuole Paritarie senza maggiorazioni</label>
                                    <div className="Cursor" style={{ margin: "10px auto", padding: "10px 20px", fontSize: "14px", borderRadius: "0.5rem", background: "hsl(162, 50%, 61%)", color: "white", fontWeight: "600", width: "100%" }} id="InvioMADNav2" /*to='/InvioMAD'*/ onClick={() => { navigate('/InvioMAD') }}>Acquista</div>
                                </div>
                            </div>
                        </div>
                        <div className="Card Card2" style={{ width: width_ > 900 ? "40%" : "80%" }}>
                            <h4 style={{ color: "white", marginTop: "2rem", fontWeight: "600", textAlign: "center" }}>MAD illimitata</h4>
                            <p className="costo" style={{ fontWeight: "600", fontSize: "22px", /*textDecoration: "line-through",*/ color: "var(--color20)"/*"#da4100"*/ }}>€65,90</p>
{    /*                        <div className="costo" style={{ fontSize: "16px", color: "black" }}><span style={{ fontSize: "22px", color: "black" }}>-30% </span> fino a fine luglio</div>
                            <p className="costo" style={{ fontWeight: "600", color: "var(--color20)", marginBottom: "0", fontSize: "30px" }}>€45,90</p>
                            <div style={{ textAlign: "center", fontWeight: 500, fontSize: "12px", marginBottom: "2rem", fontStyle: "italic", color: "black" }}>Compra ora, invia quando vuoi</div>*/}

                            {/*<p className="costo" style={{ color: "var(--color9)" }}>€65,90</p>*/}
                            <div className="container-Features container-Features2">
                                <div className="features">
                                    <label>Un grado di istruzione</label>
                                    <label className="label2">Prezzo fisso per province/regioni illimitate</label>
                                    <label>Invio tramite portali <b>Spaggiari (Bergantini), Nuvola, Argo e Axios</b></label>
                                    <label>Classi di concorso/Profili professionali illimitati</label>
                                    <label>Invio veloce (seleziona e invia con un solo click)</label>
                                    <label>Personalizzazione Mail</label>
                                    <label>Possibilità di invio alle scuole Paritarie senza maggiorazioni</label>
                                    <div className="Cursor" style={{ margin: "10px auto", padding: "10px 20px", fontSize: "14px", borderRadius: "0.5rem", background: "hsl(210, 65%, 45%)", color: "white", fontWeight: "600", width: "100%" }} id="InvioMADNav3" /*to='/InvioMAD'*/ onClick={() => { navigate('/InvioMAD') }}>Acquista</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <p id="AvvertenzaPrezzo"></p>
                </div>
            </section>

        </div>
    )
}
